interface FieldItemProps {
  name: string;
  value: string;
  className?: string;
  postfix?: string;
}

const FieldItem: React.FC<FieldItemProps> = ({
  name,
  value,
  className = "",
  postfix,
}) => {
  return (
    <div className={`${className}`}>
      <p className="text-xs text-neutral-content">{name}:</p>
      <div className="flex items-center">
        <p className="text-base">{value}</p>
        {postfix && <p className="text-base ml-2 font-bold">{postfix}</p>}
      </div>
    </div>
  );
};

export { FieldItem };
