import React from "react";

interface IsCheckedBadgeProps {
  isChecked: boolean;
  className?: string;
}

const IsCheckedBadge: React.FC<IsCheckedBadgeProps> = ({
  className = "",
  isChecked,
}) => {
  let name = "";
  let addedClassName = "";

  if (isChecked) {
    name = "Проверено";
    addedClassName = "badge-success";
  } else {
    name = "Не проверено";
    addedClassName = "badge-error";
  }

  return <div className={`badge ${addedClassName} ${className}`}>{name}</div>;
};

export { IsCheckedBadge };
