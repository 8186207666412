import React, { useState } from "react";

import { OrderReceiveInterface, OrderReceiveStatus } from "types/Order";
import { FieldItem } from "components/Common/FieldItem";
import { Timer } from "components/Common/Timer";
import axios from "axios";
import { toast } from "react-toastify";

interface OrderReceiveContent {
  order: OrderReceiveInterface;
  className?: string;
}

const ReceiveContent: React.FC<OrderReceiveContent> = ({
  className = "",
  order,
}) => {
  let comment = order.comment;
  let commentClassName = "";
  let isShowComment = order.status == "done" || order.status == "cancel";

  if (order.status == "done") {
    commentClassName = "alert-info";
  } else if (order.status == "cancel") {
    commentClassName = "alert-error";
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <FieldItem name="Сумма" value={`${order.sum}`} postfix="RUB" />
        <div>
          {order.status == "wait" && <Timer finished={order.expired} />}
        </div>
      </div>
      <div className=" card bg-neutral shadow-xl">
        <div className="px-4 py-3">
          <p className="mb-2 mt-2">
            Прибыль партнера: <b>{order.profitSum}</b> RUB (
            <b>{order.profitPercent}</b>%)
          </p>
          {isShowComment && (
            <div role="alert" className={`alert ${commentClassName} p-2`}>
              <span>
                Комментарий: <b>{comment}</b>
              </span>
            </div>
          )}
          <div className="divider"></div>

          <p className="font-bold mt-2 mb-1">Откуда получает:</p>
          {order.senderFields.map((f) => {
            return <FieldItem key={f._id} name={f.name} value={f.value} />;
          })}
          <div className="divider"></div>

          <p className="font-bold mt-2 mb-1">Куда получает:</p>
          {order.recipientFields.map((f) => {
            return <FieldItem key={f._id} name={f.name} value={f.value} />;
          })}
        </div>
      </div>
      <Btns _id={order._id} status={order.status} isChecked={order.isChecked} />
    </div>
  );
};

interface BtnsProps {
  status: OrderReceiveStatus;
  isChecked: boolean;
  _id: string;
}

const Btns: React.FC<BtnsProps> = ({ status, isChecked, _id }) => {
  let [submit, setSubmit] = useState<string>("");
  let [isCancelView, setCancelView] = useState<boolean>(false);
  let [comment, setComment] = useState<string>("");

  const changeStatusHandler = (newStatus: string, comment: string = "") => {
    setSubmit(newStatus);
    axios
      .put("/orders/receive", {
        newStatus,
        _id: _id,
        comment,
      })
      .then(() => {
        toast.success("Статус изменен");
        setCancelView(false);
      })
      .finally(() => {
        setSubmit("");
      });
  };

  const checkedHandler = () => {
    setSubmit("checked");
    axios
      .put("/orders/receive/checked", {
        _id: _id,
      })
      .then(() => {
        toast.success("Заявка проверена! Удерживаемая сумма освобождена.");
        setCancelView(false);
      })
      .finally(() => {
        setSubmit("");
      });
  };

  const recoverHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    changeStatusHandler("wait");
  };
  const doneHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    changeStatusHandler("done", "Администратор подтвердил получение средств.");
  };

  const cancelHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    changeStatusHandler(
      "cancel",
      comment || "Администратор отменил получение средств."
    );
  };

  let view = null;

  const cancelBtns = (
    <>
      <div className="col-span-12">
        <div className="label">
          <span className="label-text">Комментарий</span>
        </div>
        <input
          type="text"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Не обязательно"
          className="input input-bordered input-md w-full"
        />
      </div>
      <div className="col-span-6">
        <button
          onClick={cancelHandler}
          disabled={submit == "cancel"}
          className="btn btn-outline btn-ghost w-full"
        >
          {submit == "cancel" && (
            <span className="loading loading-spinner"></span>
          )}
          Отклонить
        </button>
      </div>
      <div className="col-span-6">
        <button
          onClick={() => setCancelView(false)}
          className="btn btn-outline btn-error w-full"
        >
          Отменить
        </button>
      </div>
    </>
  );

  if (status == "wait") {
    view = (
      <>
        <div className="col-span-6">
          <button
            onClick={doneHandler}
            disabled={submit == "done"}
            className="btn btn-outline btn-primary w-full"
          >
            {submit == "done" && (
              <span className="loading loading-spinner"></span>
            )}
            Получил
          </button>
        </div>
        <div className="col-span-6">
          <button
            onClick={() => setCancelView(true)}
            className="btn btn-outline btn-error w-full"
          >
            Отклонить
          </button>
        </div>
      </>
    );
  } else if (status == "cancel") {
    view = (
      <>
        <div className="col-span-6">
          <button
            onClick={recoverHandler}
            disabled={submit == "wait"}
            className="btn btn-outline btn-warning w-full"
          >
            {submit == "wait" && (
              <span className="loading loading-spinner"></span>
            )}
            Восстановить
          </button>
        </div>
        {!isChecked && (
          <div className="col-span-6">
            <button
              onClick={checkedHandler}
              disabled={submit == "checked"}
              className="btn btn-outline btn-success w-full"
            >
              {submit == "checked" && (
                <span className="loading loading-spinner"></span>
              )}
              Проверено
            </button>
          </div>
        )}
      </>
    );
  } else if (status == "done") {
    view = (
      <>
        <div className="col-span-6">
          <button
            onClick={() => setCancelView(true)}
            className="btn btn-outline btn-error w-full"
          >
            Отклонить
          </button>
        </div>
      </>
    );
  }

  if (isCancelView) {
    view = cancelBtns;
  }

  return (
    <div className="grid items-center justify-between grid-cols-12 gap-2 mt-6">
      {view}
    </div>
  );
};

// import { useState, useEffect } from "react";

// import {
//   Card,
//   CardContent,
//   Box,
//   Grid,
//   Typography,
//   Link as MuiLink,
//   Button,
//   TextField,
//   CircularProgress,
// } from "@mui/material";

// import Timer from "src/modules/Orders/Timer";

// import MerchantService from "src/service/Merchant";

// import socket from "src/socket";

// import { toast } from "react-hot-toast";

// import OrderPartnerStatusChip from "./StatusChip";

// function MerchantOrderCard({ data, orderId }) {
//   let [order, setOrder] = useState(data);

//   useEffect(() => {
//     socket.on("merchant_order-receive_upd", (upd) => {
//       setOrder((order) => Object.assign({}, order, { ...upd }));
//     });
//     return () => {
//       socket.off("merchant_order-receive_upd");
//     };
//   }, []);

//   return (
//     <Card sx={{ bgcolor: (t) => t.palette.background.paper, mb: 2 }}>
//       <CardContent
//         sx={{
//           p: "12px 16px !important",
//         }}
//       >
//         <Actions
//           status={order.status}
//           orderId={orderId}
//           isChecked={order.isChecked}
//         />
//       </CardContent>
//     </Card>
//   );
// }

// function Actions({ status, orderId, isChecked }) {
//   let [submit, setSubmit] = useState(false);
//   let [isCancelView, setCancelView] = useState(false);
//   let [comment, setComment] = useState("");

//   let view = null;

//   const changeStatusHandler = (newStatus, comment = "") => {
//     setSubmit(newStatus);
//     MerchantService.request("admin.ser_order_receive-change", {
//       newStatus,
//       id: orderId,
//       comment,
//     })
//       .then(() => {
//         toast.success("Статус изменен");
//         setCancelView(false);
//       })
//       .finally(() => {
//         setSubmit();
//       });
//   };

//   const checkedHandler = () => {
//     setSubmit("checked");
//     MerchantService.request("admin.ser_order_receive-checked", {
//       id: orderId,
//     })
//       .then(() => {
//         toast.success("Заявка проверена! Удерживаемая сумма освобождена.");
//         setCancelView(false);
//       })
//       .finally(() => {
//         setSubmit();
//       });
//   };

//   const recoverHandler = () => {
//     if (!window.confirm("Вы уверены?")) return;
//     changeStatusHandler("wait");
//   };
//   const doneHandler = () => {
//     if (!window.confirm("Вы уверены?")) return;
//     changeStatusHandler("done", "Администратор подтвердил получение средств.");
//   };

//   const cancelHandler = () => {
//     if (!window.confirm("Вы уверены?")) return;
//     changeStatusHandler(
//       "cancel",
//       comment || "Администратор отменил получение средств."
//     );
//   };

//   if (isCancelView) {
//     view = (
//       <Grid sx={{ mt: 2 }} container spacing={2}>
//         <Grid item xs={12}>
//           <TextField
//             fullWidth
//             label="Причина отмены"
//             variant="outlined"
//             size="small"
//             multiline
//             rows={2}
//             value={comment}
//             onChange={(e) => setComment(e.target.value)}
//           />
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <Button
//             onClick={cancelHandler}
//             disabled={submit}
//             startIcon={submit == "cancel" ? <CircularProgress /> : null}
//             fullWidth
//             color="error"
//             variant="contained"
//           >
//             Отклонить
//           </Button>
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <Button
//             onClick={() => setCancelView(false)}
//             fullWidth
//             color="error"
//             variant="outlined"
//           >
//             Отменить
//           </Button>
//         </Grid>
//       </Grid>
//     );
//   }

//   return <Box sx={{ mt: 2 }}>{view}</Box>;
// }

// export default MerchantOrderCard;

export default ReceiveContent;
