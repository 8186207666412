// import React from "react";
import "./App.css";

import MainRouter from "Router";

import { AuthProvider } from "context/AuthContext";
import { UIProvider } from "context/UIContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/toastify.css";

import RequisiteDrawer from "modules/Requisite/RequisiteDrawer";
import DepositDrawer from "modules/Deposit/DepositDrawer";
import OrderDrawer from "modules/Order/OrderDrawer";

function App() {
  return (
    <div className="min-h-screen" data-theme="dracula">
      <UIProvider>
        <AuthProvider>
          <MainRouter />
          <RequisiteDrawer />
          <DepositDrawer />
          <OrderDrawer />
        </AuthProvider>
      </UIProvider>

      <ToastContainer />
    </div>
  );
}

export default App;
