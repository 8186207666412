import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import axios from "axios";

// types
import { AccountInterface } from "types/Account";
import { RequisiteInterface } from "types/Requisite";
import { DepositInterface } from "types/Deposit";
import { OrderInterface } from "types/Order";
import { HistoryUserInterface } from "types/History";

import { OrderCard } from "modules/Order/Card";
import RequisiteCard from "modules/Requisite/RequisiteCard";
import { DepositCard } from "modules/Deposit/Card";
import moment from "moment";

import { Icon } from "@iconify/react";

type TabCode = "orders" | "requisites" | "deposits" | "history" | "logs";

const tabs: {
  code: TabCode;
  name: string;
}[] = [
  {
    code: "orders",
    name: "Заявки",
  },
  {
    code: "requisites",
    name: "Реквизиты",
  },
  {
    code: "deposits",
    name: "Депозиты",
  },
  {
    code: "history",
    name: "История",
  },
  {
    code: "logs",
    name: "Логи",
  },
];

const UserPage: React.FC = () => {
  const [account, setAccount] = useState<AccountInterface | null>(null);
  const [tab, setTab] = useState<TabCode>("orders");

  const { id } = useParams();

  useEffect(() => {
    axios.get(`/accounts/${id}`).then(({ data }) => {
      setAccount(data);
    });
  }, []);

  if (!account) return null;

  let { blocked, createdAt, email, enabled, fullName, phone, telegram } =
    account;

  let nickName = telegram.nickName;

  let { balance, holdBalance, useHoldBalance } = account.balance;

  return (
    <div className="  px-4 mt-4">
      <div className="flex items-center justify-between mb-5">
        <p className="text-xl font-bold">{email}</p>
        <div className="flex items-center">
          <div
            className={`badge  badge-${enabled ? "ghost" : "secondary"} mr-3`}
          >
            {blocked ? "Заблокирован" : "Активный"}
          </div>
          <div className={`badge  badge-${enabled ? "primary" : "accent"}`}>
            {enabled ? "В работе" : "Отключен"}
          </div>
        </div>
      </div>
      <div className="card bg-base-300 shadow-xl">
        <div className="px-4 py-3">
          <div className="grid items-center grid-cols-12 gap-2">
            <div className="lg:col-span-4 col-span-6">
              <p className="text-xs text-neutral-content">Имя</p>
              <p>{fullName || "Неизвестно"}</p>
            </div>
            <div className="lg:col-span-4 col-span-6">
              <p className="text-xs text-neutral-content">Телефон</p>
              <p>{phone || "Неизвестно"}</p>
            </div>
            <div className="lg:col-span-4 col-span-6">
              <p className="text-xs text-neutral-content">Telegram</p>
              <p>{nickName ? `@${nickName}` : "Неизвестно"}</p>
            </div>
          </div>

          <p className="text-lg font-bold mt-4">Баланс:</p>
          <div className="grid items-center grid-cols-12 gap-2 mt-2">
            <div className="lg:col-span-4 col-span-6">
              <p className="text-xs text-neutral-content">Оборотный</p>
              <p className="text-primary">{balance} USDT</p>
            </div>
            <div className="lg:col-span-4 col-span-6">
              <p className="text-xs text-neutral-content">Обеспечительный </p>
              <p className="text-secondary">{holdBalance} USDT</p>
            </div>
            <div className="lg:col-span-4 col-span-6">
              <p className="text-xs text-neutral-content">Удерживаемый</p>
              <p className="text-accent">{useHoldBalance} USDT</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 ">
        <div role="tablist" className="tabs tabs-boxed overflow-x-auto">
          {tabs.map((t) => {
            return (
              <div
                onClick={() => setTab(t.code)}
                role="tab"
                className={`tab ${t.code == tab ? "tab-active" : ""}`}
              >
                {t.name}
              </div>
            );
          })}
        </div>
        <div>
          {tab == "orders" && !!account && (
            <OrdersSection accountId={account._id} />
          )}
          {tab == "requisites" && !!account && (
            <RequisitesSection accountId={account._id} />
          )}
          {tab == "deposits" && !!account && (
            <DepositsSection accountId={account._id} />
          )}
          {tab == "history" && !!account && (
            <HistorySection accountId={account._id} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserPage;

const OrdersSection: React.FC<{
  accountId: string;
}> = ({ accountId }) => {
  useEffect(() => {
    axios.get("/orders/account/" + accountId).then(({ data }) => {
      setOrders(data);
    });
  }, []);

  const [orders, setOrders] = useState<OrderInterface[]>([]);

  return (
    <div>
      <div className="mt-4">
        {orders.map((r) => (
          <OrderCard data={r} key={r._id} className="mb-3" />
        ))}
      </div>
    </div>
  );
};

const RequisitesSection: React.FC<{
  accountId: string;
}> = ({ accountId }) => {
  const [requisites, setRequisites] = useState<RequisiteInterface[]>([]);
  useEffect(() => {
    axios.get("/requisites/account/" + accountId).then(({ data }) => {
      setRequisites(data);
    });
  }, []);

  return (
    <div>
      <div className="mt-4">
        <div className="mt-4">
          {requisites.map((r) => (
            <RequisiteCard data={r} key={r._id} className="mb-3" />
          ))}
        </div>
      </div>
    </div>
  );
};

const DepositsSection: React.FC<{
  accountId: string;
}> = ({ accountId }) => {
  const [deposits, setDeposits] = useState<DepositInterface[]>([]);
  useEffect(() => {
    axios.get("/deposits/account/" + accountId).then(({ data }) => {
      setDeposits(data);
    });
  }, []);

  return (
    <div>
      <div className="mt-4">
        <div className="mt-4">
          {deposits.map((r) => (
            <DepositCard data={r} key={r._id} className="mb-3" />
          ))}
        </div>
      </div>
    </div>
  );
};

const HistorySection: React.FC<{
  accountId: string;
}> = ({ accountId }) => {
  const [histories, setHistories] = useState<HistoryUserInterface[]>([]);
  useEffect(() => {
    axios.get("/history/account/" + accountId).then(({ data }) => {
      setHistories(data);
    });
  }, []);

  return (
    <div>
      <div className="mt-4">
        <div className="mt-4">
          <div className="overflow-x-auto">
            <table className="table table-md  table-pin-cols">
              <thead>
                <tr>
                  <td>Событие</td>
                  <td>Сумма</td>
                  <td>Прибыль</td>
                  <td>Статус</td>
                  <td>Баланс</td>
                  <td>Дата</td>
                </tr>
              </thead>
              <tbody>
                {histories.map((h) => {
                  return (
                    <tr key={h._id}>
                      <td>
                        <p className={`font-bold ${getEventColor(h.action)}`}>
                          {getEventName(h.dir)}
                        </p>
                      </td>
                      <td>
                        <div>
                          <p className="text-xs">
                            {h.sumUSD && `${h.sumUSD} USDT`}
                          </p>
                          <p className="text-xs">
                            {h.sumRUB && `${h.sumRUB} RUB`}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <p className="text-xs">
                            {h.profitUSD && `${h.profitUSD} USDT`}
                          </p>
                          <p className="text-xs">
                            {h.profitRUB && `${h.profitRUB} RUB`}
                          </p>
                        </div>
                      </td>
                      <td>{getIcon(h.status)}</td>
                      <td>
                        <p className="text-xs">{h.balance} USDT</p>
                      </td>
                      <td>
                        <p className="text-xs text-neutral-content">
                          {moment(h.createdAt).format("HH:mm DD.MM.YYYY")}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

function getEventName(name: string) {
  switch (name) {
    case "deposit":
      return "Пополнение";
    case "withdraw":
      return "Вывод";
    case "receive":
      return "Получение";
    case "send":
      return "Перевод";
    default:
      return "Неизвестно";
  }
}

function getIcon(status: string) {
  if (status == "ok") {
    return (
      <p className="text-success">
        <Icon fontSize="18px" icon="bi:check-circle-fill" />
      </p>
    );
  } else if (status == "cancel") {
    return (
      <p className="text-error">
        <Icon fontSize="18px" icon="maki:cross" />
      </p>
    );
  }
}

function getEventColor(status: string) {
  if (status == "add") {
    return "text-success";
  } else if (status == "sub") {
    return "text-error";
  }
}
