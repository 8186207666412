import React from "react";

import { useNavigate } from "react-router-dom";

import { DepositInterface } from "types/Deposit";

import { DepositActionBadge } from "components/Deposit/ActionBadge";
import { DepositStatusBadge } from "components/Deposit/StatusBadge";

import moment from "moment";

interface DepositCardProps {
  className?: string;
  data: DepositInterface;
}

const DepositCard: React.FC<DepositCardProps> = ({ data, className = "" }) => {
  const navigate = useNavigate();

  const toClientHandler = (event: React.MouseEvent<HTMLParagraphElement>) => {
    event.stopPropagation();
    navigate(`/users/${data.account._id}`);
  };

  const openDepositDrawer = () => {
    navigate(`?depositId=${data._id}`);
  };

  return (
    <div
      onClick={openDepositDrawer}
      className={`cursor-pointer card bg-base-300 shadow-xl ${className}`}
    >
      <div className="px-4 py-3 ">
        <div className="grid items-center justify-between grid-cols-12 gap-2">
          <div className="lg:col-span-3 col-span-6">
            <div className="flex ">
              <DepositActionBadge status={data.action} />
            </div>
          </div>
          <div className="lg:col-span-3 col-span-6">
            <DepositStatusBadge className="ml-2" code={data.code} />
          </div>
          <div className="lg:col-span-3 col-span-6">
            <p
              onClick={toClientHandler}
              className="text-base font-bold link link-primary"
            >
              {data.account.email}
            </p>
          </div>
          <div className="lg:col-span-3 col-span-6">
            <p className="text-base font-bold">{data.sum} USDT</p>
          </div>
        </div>
        <div className="flex justify-end">
          <p className="text-sm text-neutral-content">
            {moment(data.createdAt).format("HH:mm DD.MM.YY")}
          </p>
        </div>
      </div>
    </div>
  );
};

export { DepositCard };
