import React, { useState } from "react";

import { Icon } from "@iconify/react";

// Validate
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

// Notifi
import { toast } from "react-toastify";

// Hooks
import { useAuth } from "hooks/useAuth";

const schema = yup.object({
  email: yup.string().email("Не корректный email").required("Введите Email"),
  password: yup
    .string()
    .required("Введите пароль")
    .min(6, "Мин. 6 символов")
    .max(30, "Макс. 30 символов"),
});

const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const { login } = useAuth();

  const onSubmit = handleSubmit((d) => {
    setIsLoading(true);
    axios
      .post("/login", {
        email: d.email,
        password: d.password,
      })
      .then(({ data }) => {
        if (data) {
          login(data);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setIsLoading(false);
      });
  });

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-full max-w-md p-8 space-y-8 rounded-lg card card-compact w-96 bg-neutral shadow-xl">
        <h2 className="text-center text-2xl font-bold">Вход</h2>
        <form className="mt-8 space-y-6" onSubmit={onSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            <Controller
              name={"email"}
              control={control}
              render={({ field: { onChange, value } }) => {
                let errorMessage = errors.email?.message;

                return (
                  <div className="mb-4 ">
                    <label
                      className={`input input-bordered flex items-center gap-2 ${
                        errorMessage && "input-error"
                      }`}
                    >
                      <Icon icon="material-symbols:mail-outline" />
                      <input
                        type="email"
                        {...register("email")}
                        id="email"
                        value={value}
                        onChange={onChange}
                        className="grow"
                        placeholder="Email"
                      />
                    </label>
                    {Boolean(errorMessage) && (
                      <span className="text-red-500">{errorMessage}</span>
                    )}
                  </div>
                );
              }}
            />

            <Controller
              name={"password"}
              control={control}
              render={({ field: { onChange, value } }) => {
                let errorMessage = errors.password?.message;

                return (
                  <div className="mb-4 ">
                    <label
                      className={`input input-bordered flex items-center gap-2 ${
                        errorMessage && "input-error"
                      }`}
                    >
                      <Icon icon="mdi:password-outline" />
                      <input
                        type="password"
                        {...register("password")}
                        id="password"
                        value={value}
                        onChange={onChange}
                        className="grow"
                        placeholder="Пароль"
                      />
                    </label>
                    {Boolean(errorMessage) && (
                      <span className="text-red-500">{errorMessage}</span>
                    )}
                  </div>
                );
              }}
            />

            {/* <label className="input input-bordered flex items-center gap-2">
              <Icon icon="mdi:password-outline" />
              <input
                type="password"
                value={password}
                placeholder="Пароль"
                onChange={(e) => setPassword(e.target.value)}
                className="grow"
              />
            </label> */}
          </div>

          <div>
            <button
              onClick={onSubmit}
              type="submit"
              className="btn btn-primary btn-block"
            >
              {isLoading && <span className="loading loading-spinner" />}
              Войти
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
