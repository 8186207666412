import axios from "axios";
import React, { useState, useEffect } from "react";

import {
  OrderReceiveInterface,
  OrderSendInterface,
  OrderInterface,
} from "types/Order";

import { OrderCard } from "modules/Order/Card";

const Orders: React.FC = () => {
  useEffect(() => {
    axios.get("/orders").then(({ data }) => {
      setOrders(data);
    });
  }, []);

  const [orders, setOrders] = useState<OrderInterface[]>([]);

  return (
    <div className="  px-4 mt-4">
      <h4 className="text-2xl">Заявки</h4>
      <div className="mt-4">
        {orders.map((r) => (
          <OrderCard data={r} key={r._id} className="mb-3" />
        ))}
      </div>
    </div>
  );
};

export default Orders;
