import React, { useState, useEffect } from "react";

import axios from "axios";

import { useParams, useLocation, useSearchParams } from "react-router-dom";

import { Icon } from "@iconify/react";
import moment from "moment";
import { RequisiteInterface, RequisiteStatusType } from "types/Requisite";
// components
import { RequisiteStatusBadge } from "components/Requisite/StatusBadge";
import { RequisiteStatusEnabled } from "components/Requisite/StatusEnabled";
import { CurrencyInfo } from "components/Currency/CurrencyInfo";
import { FieldItem } from "components/Common/FieldItem";
import { Link } from "components/Common/Link";

// libs

import { toast } from "react-toastify";
import socket from "socket";
const RequisiteDrawer: React.FC = () => {
  const [requisite, setRequisite] = useState<RequisiteInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const requisiteId = searchParams.get("requisiteId");

  // Hooks
  useEffect(() => {
    if (!requisiteId) {
      setRequisite(null);
      return;
    }

    setLoading(true);
    axios.get(`/requisites/${requisiteId}`).then(({ data }) => {
      setRequisite(data);
      setLoading(false);
    });
  }, [requisiteId]);

  // Handler
  const handleClose = () => {
    searchParams.delete("requisiteId");
    setSearchParams(searchParams);
  };

  const isOpen = !!requisiteId;

  const isLoaded = !loading && !!requisite;

  return (
    <div className="drawer drawer-end">
      <input
        id="requisite-drawer"
        type="checkbox"
        checked={isOpen}
        className="drawer-toggle"
      />

      <div className="drawer-side">
        <label
          htmlFor="mrequisite-drawe"
          aria-label="close sidebar"
          className="drawer-overlay"
          onClick={handleClose}
        ></label>
        <div className="menu w-full sm:w-500px min-h-full bg-base-200 text-base-content">
          <div className="p-3">
            <div className="flex justify-end">
              <button onClick={handleClose} className="btn btn-circle">
                <Icon fontSize="26" icon="ic:round-close" />
              </button>
            </div>
            {isLoaded ? <RequisiteDrawerContent data={requisite} /> : "loading"}
          </div>
        </div>
      </div>
    </div>
  );
};

interface RequisiteDrawerContentProps {
  data: RequisiteInterface;
}

const RequisiteDrawerContent: React.FC<RequisiteDrawerContentProps> = ({
  data: initData,
}) => {
  const [requisite, setRequisite] = useState<RequisiteInterface>(initData);

  let isNotVerify = requisite.status == "not-verified";
  let isFailVerify = requisite.status == "fail-verified";

  useEffect(() => {
    socket.on("requisite_upd", (upd: RequisiteInterface) => {
      setRequisite((prev) => (prev._id == upd._id ? upd : prev));
    });

    return () => {
      socket.off("requisite_upd");
    };
  }, []);

  return (
    <div>
      <div>
        <RequisiteStatusBadge className="badge-lg" status={requisite.status} />
      </div>
      <div className="mt-3">
        <RequisiteStatusEnabled
          enabled={requisite.enabled}
          className="badge-lg"
          size="medium"
        />
      </div>
      <CurrencyInfo
        name={requisite.currency.name}
        logo={requisite.currency.logo}
        size="small"
        className="mt-3"
      />
      <Link
        name={requisite.account.email}
        href={`/users/${requisite.account._id}`}
        className="mt-3"
      />
      <div className="flex justify-end items-center mt-3">
        <p className="text-sm text-neutral-content mt-2">
          {moment(requisite.createdAt).format("HH:mm DD.MM.YY")}
        </p>
      </div>
      <div className="divider"></div>
      <div className="mt-6">
        <div className="flex justify-between">
          <FieldItem name="Название пользователя" value={requisite.name} />
          <FieldItem
            className=""
            name="Баланс"
            value={`${requisite.balance}`}
            postfix="RUB"
          />
        </div>

        <div className="mt-3 card bg-neutral shadow-xl">
          <div className="px-4 py-3">
            <div>
              {isFailVerify && requisite.comment && (
                <div role="alert" className="alert alert-error p-2 mb-2">
                  <span> Комментарий: {requisite.comment}</span>
                </div>
              )}
              {isNotVerify && (
                <div role="alert" className="alert alert-info p-2 mb-2">
                  <span>
                    Пользователь еще не подтвердил отправление на проверку.
                  </span>
                </div>
              )}
            </div>
            <div className="mt-4">
              <p className="font-bold mt-2 mb-1">Поля реквизита:</p>
              {requisite.fields.map((r) => {
                return (
                  <FieldItem
                    key={r._id}
                    name={r.name}
                    value={r.value}
                    className="mb-2"
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {!isNotVerify && (
        <RequisiteBtns status={requisite.status} _id={requisite._id} />
      )}
    </div>
  );
};

interface RequisiteBtnsProps {
  status: RequisiteStatusType;
  _id: string;
  className?: string;
}

const RequisiteBtns: React.FC<RequisiteBtnsProps> = ({
  status,
  _id,
  className = "",
}) => {
  let [submit, setSubmit] = useState<string>("");
  let [isViewCancel, setIsViewCancel] = useState<boolean>(false);
  let [comment, setComment] = useState<string>("");

  let view = null;

  const blockHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    setSubmit("blocked");
    axios
      .put("/requisites", {
        action: "blocked",
        _id,
      })
      .then(() => {
        toast.success("Реквизит заблокирован");
      })
      .finally(() => {
        setSubmit("");
      });
  };

  const verifiedHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    setSubmit("verified");
    axios
      .put("/requisites", {
        action: "verified",
        _id,
      })
      .then(() => {
        toast.success("Реквизит проверен");
      })
      .finally(() => {
        setSubmit("");
      });
  };

  const cancelHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    setSubmit("fail-verified");
    axios
      .put("/requisites", {
        action: "fail-verified",
        _id,
        comment,
      })
      .then(() => {
        toast.success("Реквизит отклонен");
        setIsViewCancel(false);
      })
      .finally(() => {
        setSubmit("");
      });
  };

  const cancelBtns = (
    <>
      <div className="col-span-12">
        <div className="label">
          <span className="label-text">Комментарий</span>
        </div>
        <input
          type="text"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Не обязатьельно"
          className="input input-bordered input-md w-full"
        />
      </div>
      <div className="col-span-6">
        <button
          onClick={cancelHandler}
          disabled={submit == "fail-verified"}
          className="btn btn-outline btn-ghost w-full"
        >
          {submit == "fail-verified" && (
            <span className="loading loading-spinner"></span>
          )}
          Отклонить
        </button>
      </div>
      <div className="col-span-6">
        <button
          onClick={() => setIsViewCancel(false)}
          className="btn btn-outline btn-error w-full"
        >
          Отменить
        </button>
      </div>
    </>
  );

  const cancelBtn = (
    <button
      onClick={() => setIsViewCancel(true)}
      className="btn btn-outline btn-error w-full"
    >
      Отклонить
    </button>
  );

  const verifiedBtn = (
    <button
      onClick={verifiedHandler}
      disabled={submit == "verified"}
      className="btn btn-outline btn-primary w-full"
    >
      {submit == "verified" && (
        <span className="loading loading-spinner"></span>
      )}
      Проверено
    </button>
  );

  const blockedBtn = (
    <button
      onClick={blockHandler}
      disabled={submit == "blocked"}
      className="btn btn-outline btn-ghost w-full"
    >
      {submit == "blocked" && <span className="loading loading-spinner"></span>}
      Заблокировать
    </button>
  );

  if (status == "on-verified") {
    view = (
      <>
        <div className="col-span-6">{verifiedBtn}</div>
        <div className="col-span-6">{cancelBtn}</div>
        <div className="col-span-6">{blockedBtn}</div>
      </>
    );
  }
  if (status == "verified") {
    view = (
      <>
        <div className="col-span-6">{cancelBtn}</div>
        <div className="col-span-6">{blockedBtn}</div>
      </>
    );
  }
  if (status == "fail-verified") {
    view = (
      <>
        <div className="col-span-6">{verifiedBtn}</div>
        <div className="col-span-6">{blockedBtn}</div>
      </>
    );
  }
  if (status == "blocked") {
    view = (
      <>
        <div className="col-span-6">{verifiedBtn}</div>
      </>
    );
  }

  view = isViewCancel ? cancelBtns : view;

  return (
    <div>
      <div className="grid items-center justify-between grid-cols-12 gap-2 mt-6">
        {view}
      </div>
    </div>
  );
};

export default RequisiteDrawer;
