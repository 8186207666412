import React from "react";

import { RequisiteStatusType } from "types/Requisite";

interface RequisiteStatusBadgeProps {
  status: RequisiteStatusType;
  className?: string;
}

const RequisiteStatusBadge: React.FC<RequisiteStatusBadgeProps> = ({
  className = "",
  status,
}) => {
  let name = getName(status);
  let addedClassName = getBadgeClassName(status);

  return <div className={`badge ${addedClassName} ${className}`}>{name}</div>;
};

function getBadgeClassName(status: RequisiteStatusType) {
  switch (status) {
    case "not-verified":
      return "badge-accent";
    case "on-verified":
      return "badge-warning";
    case "verified":
      return "badge-success";
    case "fail-verified":
      return "badge-error";
    case "blocked":
      return "badge-ghost";
    default:
      return "";
  }
}

function getName(status: RequisiteStatusType) {
  switch (status) {
    case "not-verified":
      return "Ожидание";
    case "on-verified":
      return "Новая";
    case "verified":
      return "Верифицирована";
    case "fail-verified":
      return "Отклонена";
    case "blocked":
      return "Заблокирована";
    default:
      return "Неизвестно";
  }
}

export { RequisiteStatusBadge };
