import React from "react";

import { OrderSendInterface } from "types/Order";
import { FieldItem } from "components/Common/FieldItem";
import { Timer } from "components/Common/Timer";

interface OrderSendContent {
  order: OrderSendInterface;
  className?: string;
}
const SendContent: React.FC<OrderSendContent> = ({ className = "", order }) => {
  let comment = order.comment;
  let commentClassName = "";
  let isShowComment = order.status == "done" || order.status == "cancel";

  if (order.status == "done") {
    commentClassName = "alert-info";
  } else if (order.status == "cancel") {
    commentClassName = "alert-error";
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <FieldItem name="Сумма" value={`${order.sum}`} postfix="RUB" />
        <div>
          {order.status == "wait" && <Timer finished={order.expired} />}
        </div>
      </div>
      <div className=" card bg-neutral shadow-xl">
        <div className="px-4 py-3">
          <p className="mb-2 mt-2">
            Прибыль партнера: <b>{order.profitSum}</b> RUB (
            <b>{order.profitPercent}</b>%)
          </p>
          {isShowComment && (
            <div role="alert" className={`alert ${commentClassName} p-2`}>
              <span>
                Комментарий: <b>{comment}</b>
              </span>
            </div>
          )}
          <div className="divider"></div>
          <p className="font-bold mt-2 mb-1">Куда переводит:</p>
        </div>
      </div>
    </div>
  );
  // return

  //     <Card sx={{ bgcolor: (t) => t.palette.background.default }}>
  //     <CardContent
  //       sx={{
  //         p: "12px 16px !important",
  //       }}
  //     >
  //
  //       {data.status == "done" && data.receipt && (
  //         <AuctionReceipt value={CONFIG.MERCHANT_IMAGES + data.receipt} />
  //       )}
  //       <Divider />
  //       <Fields fields={data.fields} name="Реквизиты получателя" />
  //       {!!data?.requisite && (
  //         <>
  //           <Divider />
  //           <Fields
  //             fields={data?.requisite?.fields}
  //             name="Реквизиты мерчанта"
  //           />
  //         </>
  //       )}

  //       <Actions style={{mt: 2}} order={data} orderId={data.orderId} />
  //     </CardContent>
  //   </Card>
};

export default SendContent;
