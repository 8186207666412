import React, { useState, useEffect } from "react";

import { Link } from "components/Common/Link";
import { CurrencyInfo } from "components/Currency/CurrencyInfo";
import { IsCheckedBadge } from "components/Order/IsCheckedBadge";
import { OrderActionBadge } from "components/Order/ActionBadge";

import { OrderReceiveStatus, OrderSendStatusType } from "types/Order";

import API from "config/API";

import moment from "moment";

import { OrderStatusBadge } from "components/Order/StatusBadge";

interface OrderDrawerInfoProps {
  createdAt: string;
  currency: {
    name: string;
    logo: string;
  };
  account: {
    email: string;
    _id: string;
  };
  isChecked: boolean;
  action: "receive" | "send";
  orderId: string;
  status: OrderReceiveStatus | OrderSendStatusType;
}

const OrderDrawerInfo: React.FC<OrderDrawerInfoProps> = ({
  createdAt,
  currency,
  account,
  isChecked,
  action,
  orderId,
  status,
}) => {
  const orderLink = `${API.EXCHANGER}/orders?orderId=${orderId}`;

  const showCheckStatus = status == "done" || status == "cancel";

  return (
    <div>
      <div className="mb-3 flex justify-between">
        <OrderActionBadge status={action} className="badge-lg" />
        <OrderStatusBadge className="badge-lg" status={status} />
      </div>

      {showCheckStatus && (
        <div className="mb-3">
          <IsCheckedBadge className="badge-lg" isChecked={isChecked} />
        </div>
      )}

      <CurrencyInfo
        name={currency.name}
        logo={currency.logo}
        size="small"
        className="mb-3"
      />
      <Link
        name={`Заявка №${orderId}`}
        href={orderLink}
        className="mb-2"
        isNewtab
      />
      <Link
        name={account.email}
        href={`/users/${account._id}`}
        className="mb-2"
      />
      <div className="flex justify-between items-center">
        <div></div>
        <p className="text-sm text-neutral-content mt-2">
          {moment(createdAt).format("HH:mm DD.MM.YY")}
        </p>
      </div>
    </div>
  );
};

export default OrderDrawerInfo;
