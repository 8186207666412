import React, { useState, useEffect } from "react";
import { OrderInterface } from "types/Order";
import moment from "moment";

import { CurrencyInfo } from "components/Currency/CurrencyInfo";
import { OrderActionBadge } from "components/Order/ActionBadge";
import { useNavigate } from "react-router-dom";



interface OrderCardProps {
  className?: string;
  data: OrderInterface;
}

const OrderCard: React.FC<OrderCardProps> = ({ className = "", data }) => {
  let statusAction: "receive" | "send";

  const navigate = useNavigate();

  if (data.isReceive) {
    statusAction = "receive";
  } else if (data.isSend) {
    statusAction = "send";
  } else {
    statusAction = "receive";
  }

  const toClientHandler = (event: React.MouseEvent<HTMLParagraphElement>) => {
    event.stopPropagation();
    navigate(`/users/${data.account._id}`);
  };

  const openOrderDrawer = () => {
    navigate(`?orderId=${data._id}`);
  };

  return (
    <div
    onClick={openOrderDrawer}
    className={`cursor-pointer card bg-base-300 shadow-xl ${className}`}>
      <div className="px-4 py-3 ">
        <div className="grid items-center justify-between grid-cols-12 gap-2">
          <div className="lg:col-span-3 col-span-6">
            <OrderActionBadge status={statusAction} />
          </div>
          <div className="lg:col-span-3 col-span-6">
            <div className="flex items-center">
              <CurrencyInfo
                className="ml-2"
                size="small"
                name={data.currency.name}
                logo={data.currency.logo}
              />
            </div>
          </div>
          <div className="lg:col-span-3 col-span-6">
            <p
                onClick={toClientHandler}
              className="text-base font-bold link link-primary"
            >
              {data.account.email}
            </p>
          </div>
          <div className="lg:col-span-3 col-span-6">
            <p className="text-base font-bold">{data.sum} RUB</p>
          </div>
        </div>
        <div className="flex justify-end">
          <p className="text-sm text-neutral-content">
            {moment(data.createdAt).format("HH:mm DD.MM.YY")}
          </p>
        </div>
      </div>
    </div>
  );
};

function TopupContent() {
  return <div>action currency</div>;
}

export { OrderCard };
