import React from "react";

import { DepositCodeType } from "types/Deposit";

interface RequisiteStatusBadgeProps {
  code: DepositCodeType;
  className?: string;
}

const DepositStatusBadge: React.FC<RequisiteStatusBadgeProps> = ({
  className = "",
  code,
}) => {
  let name = getName(code);
  let addedClassName = getBadgeClassName(code);
  return <div className={`badge ${addedClassName} ${className}`}>{name}</div>;
};

function getBadgeClassName(status: DepositCodeType) {
  switch (status) {
    case "payment":
      return "badge-accent";
    case "confirm-payment":
      return "badge-secondary";
    case "done":
      return "badge-success";
    case "cancel":
      return "badge-error";
    default:
      return "Неизвестно";
  }
}

function getName(status: DepositCodeType) {
  switch (status) {
    case "payment":
      return "Новая";
    case "confirm-payment":
      return "Оплата";
    case "done":
      return "Выполнено";
    case "cancel":
      return "Отклонено";
    default:
      return "Неизвестно";
  }
}

export { DepositStatusBadge };
