import { Outlet, useNavigate } from "react-router-dom";

import { Icon } from "@iconify/react";

import { useUI } from "hooks/useUI";
import { useAuth } from "hooks/useAuth";

import { observer } from "mobx-react-lite";

import mainStore from "store/main";

function AuthLayout() {
  const { isContentLoading } = useUI();

  const navigation = useNavigate();

  return (
    <div className="flex">
      <div className="drawer lg:drawer-open">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content ">
          <NavBar />

          {/* {isContentLoading ? <p>loading</p> : } */}
          <Outlet />
          {/* <label
            htmlFor="my-drawer-2"
            className="btn btn-primary drawer-button lg:hidden"
          >
            Open drawer
          </label> */}
        </div>
        <div className="drawer-side">
          <label
            htmlFor="my-drawer-2"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>
          <ul className="menu p-4 w-80 min-h-full bg-base-200 text-base-content">
            {navigationList.map((n) => {
              return (
                <li>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      navigation(n.path);
                    }}
                    className="text-base"
                  >
                    <Icon icon={n.iconName} fontSize="20px" />
                    {n.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

const NavBar: React.FC = observer(() => {
  const { logout } = useAuth();
  const { globalData } = mainStore;
  const { USDTValue } = globalData;
  return (
    <div className="navbar bg-base-100">
      <div className="flex justify-between items-center w-full">
        <div>
          <label
            htmlFor="my-drawer-2"
            aria-label="open sidebar"
            className="btn btn-square btn-ghost lg:hidden"
          >
            <Icon icon="gg:menu" fontSize="28px" />
          </label>
        </div>
        <div className="flex items-center">
          <p className="mr-3 text-sm ">
            <span className="text-neutral-content">USDT/RUB:</span>{" "}
            <span className="text-accent font-bold">{USDTValue}</span>
          </p>
          <div className="dropdown dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost btn-circle"
            >
              <div className="avatar placeholder">
                <div className="bg-neutral text-neutral-content rounded-full w-12">
                  <span>A</span>
                </div>
              </div>
            </div>
            <ul
              tabIndex={0}
              className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box"
            >
              <li onClick={() => logout()}>
                <p>Выход</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});

const navigationList = [
  {
    path: "/dashboard",
    name: "Панель",
    iconName: "akar-icons:panel-top",
  },
  {
    path: "/orders",
    name: "Заявки",
    iconName: "lets-icons:order",
  },
  {
    path: "/users",
    name: "Пользователи",
    iconName: "ph:users-bold",
  },
  {
    path: "/requisites",
    name: "Реквизиты",
    iconName: "mdi:cards-outline",
  },
  {
    path: "/deposits",
    name: "Депозиты",
    iconName: "ph:hand-deposit-bold",
  },
  {
    path: "/tariffs",
    name: "Тарифы",
    iconName: "ri:currency-line",
  },
  {
    path: "/logs",
    name: "Логи",
    iconName: "tabler:logs",
  },
];

export default AuthLayout;
