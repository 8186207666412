import React, { useState, useEffect } from "react";

import axios from "axios";

import socket from "socket";

// types
import { RequisiteInterface } from "types/Requisite";

// modules
import RequisiteCard from "modules/Requisite/RequisiteCard";

const Requisites: React.FC = () => {
  const [requisites, setRequisites] = useState<RequisiteInterface[]>([]);

  useEffect(() => {
    axios.get("/requisites").then(({ data }) => {
      setRequisites(data);
    });

    socket.on("requisite_upd", (upd: RequisiteInterface) => {
      setRequisites((prev) => prev.map((r) => (r._id == upd._id ? upd : r)));
    });
    socket.on("requisite_add", (add: RequisiteInterface) => {
      setRequisites((prev) => [add, ...prev]);
    });

    return () => {
      socket.off("requisite_upd");
      socket.off("requisite_add");
    };
  }, []);

  return (
    <div className="  px-4 mt-4">
      <h4 className="text-2xl">Реквизиты</h4>
      <div className="mt-4">
        {requisites.map((r) => (
          <RequisiteCard data={r} key={r._id} className="mb-3" />
        ))}
      </div>
    </div>
  );

  // requisites
};

export default Requisites;
