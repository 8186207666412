import React, { useState, useEffect } from "react";

import { DepositInterface } from "types/Deposit";

import WalletDrawer from "./WalletDrawer";

// modules
import { DepositCard } from "modules/Deposit/Card";
import { Icon } from "@iconify/react";

import axios from "axios";

import socket from "socket";

const Deposits: React.FC = () => {
  const [deposits, setDeposits] = useState<DepositInterface[]>([]);
  const [isOpenWallet, setIsOpenWallet] = useState<boolean>(false);

  useEffect(() => {
    axios.get("/deposits").then(({ data }) => {
      setDeposits(data);
    });

    socket.on("deposit_upd", (upd: DepositInterface) => {
      setDeposits((prev) => prev.map((d) => (d._id == upd._id ? upd : d)));
    });
    socket.on("deposit_add", (add: DepositInterface) => {
      setDeposits((prev) => [add, ...prev]);
    });

    return () => {
      socket.off("deposit_upd");
      socket.off("deposit_add");
    };
  }, []);

  return (
    <div className="  px-4 mt-4">
      <div className="flex justify-between items-center">
        <h4 className="text-2xl">Ввод и вывод</h4>
        <button
          onClick={() => {
            setIsOpenWallet(true);
          }}
          className="btn btn-active btn-primary btn-sm"
        >
          <Icon icon="solar:wallet-outline" />
          Кошельки
        </button>
      </div>

      <div className="mt-4">
        {deposits.map((d) => (
          <DepositCard data={d} key={d._id} className="mb-3" />
        ))}
      </div>
      <WalletDrawer
        isOpen={isOpenWallet}
        handleClose={() => {
          setIsOpenWallet(false);
        }}
      />
    </div>
  );
};
export default Deposits;
