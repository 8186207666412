import React from "react";

import moment from "moment";

import { useNavigate } from "react-router-dom";

//types
import { RequisiteInterface } from "types/Requisite";
// components
import { RequisiteStatusBadge } from "components/Requisite/StatusBadge";
import { RequisiteStatusEnabled } from "components/Requisite/StatusEnabled";

import { CurrencyInfo } from "components/Currency/CurrencyInfo";

interface RequisiteCardProps {
  data: RequisiteInterface;
  className?: string;
}

const RequisiteCard: React.FC<RequisiteCardProps> = ({
  className = "",
  data,
}) => {
  let field = data.fields.find((f) => f.isUnique);

  const navigate = useNavigate();

  const toClientHandler = (event: React.MouseEvent<HTMLParagraphElement>) => {
    event.stopPropagation();
    navigate(`/users/${data.account._id}`);
  };

  const openRequisiteDrawer = () => {
    navigate(`?requisiteId=${data._id}`);
  };

  return (
    <div
      onClick={openRequisiteDrawer}
      className={`cursor-pointer card bg-base-300 shadow-xl ${className}`}
    >
      <div className="px-4 py-3 ">
        <div className="grid items-center justify-between grid-cols-12 gap-2">
          <div className="lg:col-span-3 col-span-6 flex items-center">
            <CurrencyInfo
              name={data.currency.name}
              logo={data.currency.logo}
              size="small"
            />
            <RequisiteStatusEnabled
              enabled={data.enabled}
              className="ml-2"
              size="medium"
            />
          </div>
          <div className="lg:col-span-3 col-span-6">
            <RequisiteStatusBadge status={data.status} />
          </div>
          <div className="lg:col-span-3 col-span-6">
            <p
              onClick={toClientHandler}
              className="text-base font-bold link link-primary"
            >
              {data.account.email}
            </p>
          </div>
          <div className="lg:col-span-3 col-span-6">
            <p>{field?.value}</p>
          </div>
        </div>
        <div className="flex items-center justify-end">
          <p className="text-sm text-neutral-content">
            {moment(data.createdAt).format("HH:mm DD.MM.YY")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RequisiteCard;
