import React from "react";

import { useNavigate } from "react-router-dom";

interface LinkProps {
  name: string;
  href: string;
  className?: string;
  isNewtab?: boolean;
}

const Link: React.FC<LinkProps> = ({
  name,
  href,
  className = "",
  isNewtab = false,
}) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    if (isNewtab) {
      window.open(href);
    } else {
      navigate(href);
    }
  };

  return (
    <p
      onClick={onClickHandler}
      className={`text-base font-bold link link-primary ${className}`}
    >
      {name}
    </p>
  );
};

export { Link };
