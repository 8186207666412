import React from "react";

interface RequisiteStatusBadgeProps {
  status: "send" | "receive";
  className?: string;
}

const OrderActionBadge: React.FC<RequisiteStatusBadgeProps> = ({
  className = "",
  status,
}) => {
  let name = getName(status);
  let addedClassName = getBadgeClassName(status);

  return <div className={`badge ${addedClassName} ${className}`}>{name}</div>;
};

function getBadgeClassName(status: "receive" | "send") {
  switch (status) {
    case "receive":
      return "badge-accent";
    case "send":
      return "badge-warning";
    default:
      return "";
  }
}

function getName(status: "receive" | "send") {
  switch (status) {
    case "receive":
      return "Получение";
    case "send":
      return "Перевод";
    default:
      return "Неизвестно";
  }
}

export { OrderActionBadge };
