import { makeObservable, observable, action } from "mobx";

import { CurrencyInteface } from "types/Currency";

interface GlobalDataInterface {
  USDTValue: number;
}

class MainStore {
  currencies: CurrencyInteface[] = [];
  globalData: GlobalDataInterface = {
    USDTValue: 0,
  };
  constructor() {
    makeObservable(this, {
      currencies: observable,
      setCurrencies: action,
    });
  }
  setCurrencies(list: CurrencyInteface[]) {
    this.currencies = list;
  }
  setGlobalData(data: GlobalDataInterface){
    this.globalData = data;
  }
  updateOneCurrency(upd: CurrencyInteface) {
    this.currencies = this.currencies.map((c) => (c._id == upd._id ? upd : c));
  }
}

const mainStore = new MainStore();

export default mainStore;
