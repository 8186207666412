import React from "react";

const LogsPage: React.FC = () => {
  return (
    <div className="px-4 mt-4">
      <h4 className="text-2xl">Системные логи</h4>
      <div className="mockup-code">
        <pre data-prefix="$">
          <code>npm i daisyui</code>
        </pre>
      </div>
    </div>
  );
};

export default LogsPage;
