import React from "react";

interface RequisiteStatusEnabledProps {
  enabled: boolean;
  size: "small" | "medium";
  className?: string;
}

const RequisiteStatusEnabled: React.FC<RequisiteStatusEnabledProps> = ({
  enabled,
  className = "",
  size = "",
}) => {
  let sizeClassName;

  if (size == "small") {
    sizeClassName = "badge-sm";
  } else if (size == "medium") {
    sizeClassName = "badge-md";
  }

  return (
    <div className={`badge ${sizeClassName} ${enabled ? "badge-primary" : "badge-accent"} ${className}`}>
      {enabled ? "Вкл." : "Выкл."}
    </div>
  );
};

export { RequisiteStatusEnabled };
