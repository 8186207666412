const isDevMode = process.env.NODE_ENV === "development";

const SERVER = isDevMode
  ? "http://localhost:3001"
  : 'https://api.pay2pay.org';

const config = {
  API: `${SERVER}/admin`,
  SERVER,
  EXCHANGER: `https://admin.ex-bank.cc`
};

export default config;
