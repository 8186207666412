import React from "react";

import mainStore from "store/main";

import { observer } from "mobx-react-lite";

const PanelPage: React.FC = () => {

    const {globalData} = mainStore;
    const {USDTValue} = globalData;

  return (
    <div className="px-4 mt-4">
      <div className="grid items-center justify-between grid-cols-12 gap-2">
        <div className="lg:col-span-3 col-span-6">
          <div className="card bg-base-300 shadow-xl">
            <div className="px-4 py-3 ">
              <p className="text-base text-neutral-content">Курс USDT</p>
              <p className="mt-2">
                <b className="text-accent">{USDTValue}</b>{' '}
                RUB
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(PanelPage);
