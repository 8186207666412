import React, { useState } from "react";
import { useCountdown } from "hooks/useCountdown";

interface TimerProps {
  className?: string;
  finished: string;
}

const Timer: React.FC<TimerProps> = ({ finished, className = "" }) => {
  const { seconds } = useCountdown(finished);

  let textTime = getTextTime(seconds);

  if (!textTime) return null;

  return <div>{textTime}</div>;
};

export { Timer };


function getTextTime(seconds: number) {
  if (seconds <= 0) return null;

  let min = Math.floor(seconds / 60);
  let sec = Math.floor(seconds % 60);

  return `${min} мин. ${sec} сек.`;
}

