import React from "react";

import { CurrencyInteface } from "types/Currency";

interface CurrencyInfoProps {
  className?: string;
  size: "small" | "medium";
  name: string;
  logo: string;
}

const CurrencyInfo: React.FC<CurrencyInfoProps> = ({
  name,
  logo,
  size = "medium",
  className = "",
}) => {
  let imgSize;
  let textSize;
  if (size == "small") {
    imgSize = "w-6";
    textSize = "text-base";
  } else if (size == "medium") {
    imgSize = "w-8";
    textSize = "text-lg";
  }

  return (
    <div className={`flex items-center ${className}`}>
      <div className="avatar">
        <div className={`${imgSize} rounded-full`}>
          <img src={logo} alt={name} />
        </div>
      </div>
      <h6 className={`${textSize} ml-2 font-bold`}> {name}</h6>
    </div>
  );
};

export { CurrencyInfo };
