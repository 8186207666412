import React, { useState, useEffect } from "react";
import axios from "axios";

// types
import { AccountInterface } from "types/Account";

// Libs
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useUI } from "hooks/useUI";

const Users: React.FC = () => {
  const [users, setUsers] = useState<AccountInterface[]>([]);

  useEffect(() => {
    axios.get("/accounts").then(({ data }) => {
      setUsers(data);
    });
  }, []);

  const navigation = useNavigate();

  const toUserPageHandler = (id: string) => {
    navigation(`/users/${id}`);
  };

  return (
    <div className="  px-4 mt-4">
      <h4 className="text-2xl">Пользователи</h4>
      <div className="mt-4">
        {users.map((u) => (
          <UserCard
            onClick={toUserPageHandler}
            key={u._id}
            {...u}
            className="mb-2"
          />
        ))}
      </div>
      {/* <div className="mt-4 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
     
    </div> */}
    </div>
  );
};

interface UserCardProps extends AccountInterface {
  className?: string;
  onClick: Function;
}

const UserCard: React.FC<UserCardProps> = ({
  className = "",
  email,
  enabled,
  fullName,
  blocked,
  createdAt,
  balance: balanceObj,
  onClick,
  _id,
}) => {
  let { balance, holdBalance, useHoldBalance } = balanceObj;

  return (
    <div
      onClick={() => {
        onClick(_id);
      }}
      className={`cursor-pointer card bg-base-${
        blocked ? 200 : 300
      } shadow-xl ${className}`}
    >
      <div className="px-4 py-3">
        <div className="grid items-center grid-cols-12 gap-2">
          <div className="flex flex-wrap items-center lg:col-span-2 col-span-6">
            <div
              className={`badge badge-${enabled ? "primary" : "accent"} mr-2`}
            >
              {enabled ? "В работе" : "Отключен"}
            </div>
          </div>

          <div className="lg:col-span-4 col-span-6">
            <p className="text-lg font-bold">{email}</p>
          </div>

          <div className="lg:col-span-3 col-span-6">
            {fullName || "Без имени"}
          </div>

          <div className="lg:col-span-3 col-span-6">
            <p className="text-accent">
              {balance} ({holdBalance}/{useHoldBalance}) USDT
            </p>
          </div>
        </div>
        <div className="flex justify-end">
          <span className="text-sm text-neutral-content">
            {moment(createdAt).format("HH:mm DD.MM.YY")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Users;
