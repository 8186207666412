import React, { createContext, useState, useEffect, ReactNode } from "react";

const initContext = {
  isContentLoading: false,
  changeIsContentLoading: () => {},
};

interface ContextType {
  isContentLoading: boolean;
  changeIsContentLoading: Function;
}

interface ProviderProps {
  children: ReactNode;
}
const UIContext = createContext<ContextType>(initContext);

const UIProvider = ({ children }: ProviderProps) => {
  const [isContentLoading, setIsContentLoading] = useState<boolean>(false);

  const changeIsContentLoading = (newState: boolean) => {
    setIsContentLoading(newState);
  };

  const value = {
    isContentLoading: isContentLoading,
    changeIsContentLoading,
  };

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export { UIContext, UIProvider };
