import React, { useState, ChangeEvent } from "react";
import { observer } from "mobx-react-lite";

// Store
import mainStore from "store/main";

// Types
import { CurrencyInteface } from "types/Currency";
import axios from "axios";
import { toast } from "react-toastify";

interface TariffProps extends CurrencyInteface {
  className?: string;
}

const TariffsPage: React.FC = () => {
  const currencies = mainStore.currencies;
  return (
    <div className="  px-4 mt-4">
      <h4 className="text-2xl">Тарифы</h4>
      <div className="mt-4 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {currencies.map((c) => (
          <TariffCard key={c._id} {...c} className="col-span-1" />
        ))}
      </div>
    </div>
  );
};

const TariffCard: React.FC<TariffProps> = ({
  name,
  code,
  logo,
  className = "",
  _id,
  receivePercent: rereceivePercentInit,
  sendPercent: sendPercentInit,
}) => {
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [receivePercent, setReceivePercent] = useState<string>(
    rereceivePercentInit + ""
  );
  const [sendPercent, setSendPercent] = useState<string>(sendPercentInit + "");

  const saveHandler = () => {
    setIsSubmit(true);
    axios
      .put("/currencies", {
        _id,
        receivePercent,
        sendPercent,
      })
      .then(({ data }) => {
        toast.success("Измененно!");
        mainStore.updateOneCurrency(data);
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const isChanged =
    +receivePercent !== +rereceivePercentInit ||
    +sendPercent !== +sendPercentInit;
  return (
    <div className={`card bg-base-300 shadow-xl ${className}`}>
      <div className="px-4 py-3">
        <div className="flex items-center">
          <div className="avatar">
            <div className="w-8 rounded-full">
              <img src={logo} />
            </div>
          </div>
          <h6 className="text-lg ml-2 font-bold"> {name}</h6>
        </div>
        <div className="mt-5 grid grid-cols-2 gap-4">
          <div>
            <div className="label">
              <span className="label-text">Получение</span>
            </div>
            <input
              type="number"
              value={receivePercent}
              onChange={(e) => setReceivePercent(e.target.value)}
              placeholder="Получение"
              className="input input-bordered input-md w-full max-w-xs"
            />
          </div>
          <div>
            <div className="label">
              <span className="label-text">Перевод</span>
            </div>
            <input
              value={sendPercent}
              onChange={(e) => setSendPercent(e.target.value)}
              type="number"
              placeholder="Перевод"
              className="input input-bordered input-md w-full max-w-xs"
            />
          </div>
        </div>
        <button
          onClick={saveHandler}
          disabled={!isChanged}
          type="submit"
          className="btn btn-primary btn-md mt-3 btn-block"
        >
          {isSubmit && <span className="loading loading-spinner" />}
          Сохранить
        </button>
      </div>
    </div>
  );
};

export default observer(TariffsPage);
